import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';
import BackButton from '../components/BackButton/BackButton';
import Footer from '../components/Footer/Footer';

export const PrivacyPolicy = () => {
  return (
    <Layout>
      <main className="main-content">
        <Helmet titleTemplate="%s | Paweł Kowalewski - ekspert-finansowy.biz">
          <title>Polityka prywatności</title>
          <meta name="description" content="Polityka prywatności" />
        </Helmet>
        <article className="post">
          <h1 className="post__title">
            <BackButton />
            Polityka prywatności
          </h1>
          <div className="post__content">
            <ul>
              <li>
                Informacje ogólne. Operatorem Serwisu jest Paweł Kowalewski, ul.
                Portowa 2, 16-300 Augustów, NIP: 8461665032 Serwis realizuje
                funkcje pozyskiwania informacji o użytkownikach i ich
                zrachowaniach w następujący sposób: Poprzez dobrowolnie
                wprowadzone w formularzach informacje Poprzez zapisywanie w
                urządzeniach końcowych pliki cookie (tzw. „ciasteczka”).
                Niniejsza Polityka jest dokumentem informacyjnym i nie zastępuje
                polityki bezpieczeństwa, prowadzonej przez Operatora Serwisu
                zgodnie z przepisami o ochronie danych osobowych. W zakresie
                danych, będących danymi osobowymi w świetnie przepisów o
                ochronie danych osobowych, administratorem danych jest Operator
                Serwisu.
              </li>
              <li>
                Informacje w formularzach. Serwis zbiera informacje podane
                dobrowolnie przez użytkownika. Serwis może zapisać ponadto
                informacje o parametrach połączenia (oznaczenie czasu, adres IP)
                Dane w formularzu nie są udostępniane podmiotom trzecim inaczej,
                niż za zgodą użytkownika. Dane podane w formularzu mogą stanowić
                zbiór danych osobowych, wówczas taki zbiór jest rejestrowany
                przez Operatora Serwisu w rejestrze prowadzonym przez
                Generalnego Inspektora Ochrony Danych Osobowych. Jeśli dane w
                formularzu umożliwiają identyfikację osoby fizycznej, to osoba
                taka ma prawdo do wglądu, modyfikacji i żądania zaprzestania
                przetwarzania swoich danych w dowolnym momencie. Dane podane w
                formularzu są przetwarzane w celu wynikającym z funkcji
                konkretnego formularza, np. w celu dokonania procesu obsługi
                zgłoszenia zapytania lub kontaktu handlowego. Dane podane w
                formularzach mogą być przekazane podmiotom technicznie
                realizującym niektóre usługi – w szczególności dotyczy to
                przekazywania informacji o posiadaczu do innych podmiotów, z
                którymi Operator Serwisu w tym zakresie współpracuje.
              </li>
              <li>
                Informacja o plikach cookies. Serwis korzysta z plików cookies.
                Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, w
                szczególności pliki tekstowe, które przechowywane są w
                urządzeniu końcowym Użytkownika Serwisu i przeznaczone są do
                korzystania ze stron internetowych Serwisu. Pliki tego rodzaju
                zazwyczaj zawierają nazwę strony internetowej, z której
                pochodzą, czas przechowywania ich na urządzeniu końcowym oraz
                unikalny numer. Podmiotem zamieszczającym na urządzeniu końcowym
                Użytkownika Serwisu pliki cookies oraz uzyskującym do nich
                dostęp jest operator Serwisu lub podmioty z nim współpracujące.
                Pliki cookies wykorzystywane są w następujących celach:
                tworzenia statystyk, które pomagają zrozumieć, w jaki sposób
                Użytkownicy Serwisu korzystają ze stron internetowych, co
                umożliwia ulepszanie ich struktury i zawartości; określania
                profilu użytkownika w celu wyświetlania mu dopasowanych
                materiałów w sieciach reklamowych, w szczególności sieci Google.
                W ramach Serwisu stosowane są dwa zasadnicze rodzaje plików
                cookies: „sesyjne” (session cookies) oraz „stałe” (persistent
                cookies). Cookies „sesyjne” są plikami tymczasowymi, które
                przechowywane są w urządzeniu końcowym Użytkownika do czasu
                wylogowania, opuszczenia strony internetowej lub wyłączenia
                oprogramowania (przeglądarki internetowej). „Stałe” pliki
                cookies przechowywane są w urządzeniu końcowym Użytkownika przez
                czas określony w parametrach plików cookies lub do czasu ich
                usunięcia przez Użytkownika. Oprogramowanie do przeglądania
                stron internetowych (przeglądarka internetowa) zazwyczaj
                domyślnie dopuszcza przechowywanie plików cookies w urządzeniu
                końcowym Użytkownika. Użytkownicy Serwisu mogą dokonać zmiany
                ustawień w tym zakresie. Przeglądarka internetowa umożliwia
                usunięcie plików cookies. Możliwe jest także automatyczne
                blokowanie plików cookies Szczegółowe informacje na ten temat
                zawiera pomoc lub dokumentacja przeglądarki internetowej.
                Ograniczenia stosowania plików cookies mogą wpłynąć na niektóre
                funkcjonalności dostępne na stronach internetowych Serwisu.
                Pliki cookies zamieszczane w urządzeniu końcowym Użytkownika
                Serwisu. Zalecamy przeczytanie polityki ochrony prywatności tych
                firm, aby poznać zasady korzystania z plików cookie
                wykorzystywane w statystykach: Polityka ochrony prywatności
                Google Analytics Pliki cookie mogą być wykorzystane przez sieci
                reklamowe, w szczególności sieć Google, do wyświetlenia reklam
                dopasowanych do sposobu, w jaki użytkownik korzysta z Serwisu. W
                tym celu mogą zachować informację o ścieżce nawigacji
                użytkownika lub czasie pozostawania na danej stronie. W zakresie
                informacji o preferencjach użytkownika gromadzonych przez sieć
                reklamową Google użytkownik może przeglądać i edytować
                informacje wynikające z plików cookies przy pomocy narzędzia:
                https://www.google.com/ads/preferences/
              </li>
              <li>
                Udostępnienie i powierzenie przetwarzania danych. Dane podlegają
                udostępnieniu podmiotom zewnętrznym wyłącznie w granicach
                prawnie dozwolonych. Dane umożliwiające identyfikację osoby
                fizycznej są udostępniane wyłączenie za zgodą tej osoby.
                Operator może mieć obowiązek udzielania informacji zebranych
                przez Serwis upoważnionym organom na podstawie zgodnych z prawem
                żądań w zakresie wynikającym z żądania. W zakresie powierzenia
                przetwarzania danych osobowych Operator Serwisu zawarł pisemną
                umowę powierzenia przetwarzania danych, uregulowaną w art. 31
                ustawy o ochronie danych osobowych, z dostawcą usług
                hostingowych LH
              </li>
            </ul>
          </div>
        </article>
      </main>
      <Footer />
    </Layout>
  );
};

export default PrivacyPolicy;
